import {Container, Tab, Tabs} from "react-bootstrap";
import Button from "react-bootstrap/Button";


export function NewPrivateProjectGuid(){
    return(
        <Container>
            <h1>First steps</h1>
            <Tabs defaultActiveKey="profile" id="justify-tab-example" className="mb-3" justify>
                <Tab eventKey="profile" title="1. Profile">
                    <Container>
                        <h3> Profile </h3>
                        <ul>
                            <li>first name</li>
                            <li>second name</li>
                            <li>bio. about</li>
                            <li>logo</li>
                        </ul>
                        <Button variant="outline-primary">submit profile data</Button>
                    </Container>
                </Tab>
                <Tab eventKey="domains" title="2. Domains">
                    <Container>
                        <h3> Domains </h3>
                        <ul>
                            <li>tasks and time management</li>
                            <li>contact</li>
                            <li>metrics</li>
                            <li>personal money</li>
                            <li>... any custom domains</li>
                        </ul>

                    </Container>
                </Tab>
                <Tab eventKey="resources" title="3. Resources">
                    <Container>
                        <h3>Settings</h3>
                        <ul>
                        <li>free subscribe</li>
                            <li>ai-assistanmr</li>
                            <li>paid subcrption</li>
                        </ul>
                    </Container>
                </Tab>
                <Tab eventKey="friends" title="4. Start">
                    Tab content for Contact
                </Tab>
            </Tabs>
        </Container>

    );
}
