import {FormEvent, useState} from "react"
import { confirmPassword } from "./AuthService"
import {Alert, Container, Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

export default function ResetPassword() {
    const [username, setUsername] = useState("")
    const [confirmationCode, setConfirmationCode] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setError("")

        try {
            await confirmPassword(username, confirmationCode, newPassword)
            setSuccess(true)
        } catch (err: any) {
            setError(err?.message || "unknown error")
        }
    }

    if (success) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <Container className={"col-6 col-lg-3 col-md-4"}>
                    <h2>Reset Password</h2>
                    <p>Your password has been reset successfully! Please go to the signIn page and use new password.</p>
                    <Link to="/signIn">SignIn</Link>
                </Container>

            </div>
        )
    }

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
        <Container className={"col-6 col-lg-3 col-md-4"}>
                <h2>Reset Password</h2>
                <form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formResetPasswordEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            required placeholder="one@mail.com"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formResetPasswordConfirmationCode">
                        <Form.Label>Confirmation code</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                            The code was sent to your email on
                            <Link to="/forgotPassword"> previous step</Link>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formResetPasswordNewPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            required
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Button type="submit" variant="outline-primary">Submit</Button>
                </form>
                {error && <Alert className={"mt-4"} variant={"warning"}>{error}</Alert>}
            </Container>
        </div>
    )
}