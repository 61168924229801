import {FormEvent, useContext, useState} from 'react';
import {getErrorMessage} from "../components/shared/getErrorMessage";
import {Link, useNavigate} from "react-router-dom";
import {Alert, Container, Form, Stack} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {AuthContext} from "./AuthContext";

export function SignIn() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState("")
    const authContext = useContext(AuthContext)

    function getRidOfErrorMessageInSeveralSeconds() {
        setTimeout(() => {
            // After 3 seconds set the show value to false
            setError("");
        }, 4000)
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setError("");
        try {
            const session = await authContext?.signIn(username, password);
            console.log(session);
            navigate("/profile");
        } catch (e) {
            setError(getErrorMessage(e))
            getRidOfErrorMessageInSeveralSeconds()
        }
    };

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <Container className={"col-6 col-lg-3 col-md-4"}>
                <h2>Sign In</h2>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="formSignUpEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            required placeholder="one@mail.com"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formSignUpPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Form.Text>
                            <Stack direction="horizontal" gap={2}>
                                <Link to={"/forgotPassword"}> forgot password; </Link>
                                <Link to={"/SignUp"}> SignUp </Link>
                            </Stack>

                        </Form.Text>

                    </Form.Group>
                    <Button type="submit" variant="outline-primary">Sign In</Button>
                </Form>


                {error && <Alert className={"mt-4"} variant={"warning"}>{error}</Alert>}
            </Container>

        </div>
    );
}

export default SignIn;